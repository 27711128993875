@import '../../Colors.less';
.pageTitle {
    margin: 20px 0;
    text-align: center;
    border-left: 5px solid #ff9933 !important;
    margin-top: 14px !important;
  }
  
  .pageTitle h1 {
    font-size: 1.875rem;
    color: #192c6d;
    margin-left: 10px !important;
    text-align: left !important;
  }
  
@primary-color: #2E5CF6;