@media print {
  /*commmon */
  .no-print {
    display: none !important;
  }
  .page-break {
    page-break-before: always; /* Start the element on a new page */
  }

  .page-break-after {
    page-break-after: always; /* Ensure the element ends on the current page */
  }

  .no-page-break {
    page-break-inside: avoid; /* Prevent breaking inside the element */
  }

  /*Home */
  .do-print {
    flex: 0 0 95% !important;
    max-width: 95% !important;
    width: 95% !important;
    display: block !important;
  }
  .logo-print {
    flex: 0 0 5% !important;
    max-width: 5% !important;
    width: 5% !important;
    display: block !important;
  }

  /* Newsletter */
  .print-left {
    flex: 0 0 20% !important;
    max-width: 20% !important;
    width: 20% !important;
    display: block !important;
  }
  .print-right {
    flex: 0 0 80% !important;
    max-width: 80% !important;
    width: 80% !important;
    display: block !important;
  }

  .min-li-print {
    display: block !important;
    flex: 0 0 33.33333333% !important;
    max-width: 33.33333333% !important;
  }

  .sec-li-print {
    display: block !important;
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }

  /* Research Papers*/
  .res-left {
    display: block !important;
    flex: 0 0 30%;
    max-width: 30% !important;
    width: 30% !important;
    margin-top: 20px;
  }

  .res-right {
    display: block !important;
    flex: 0 0 70%;
    max-width: 70% !important;
    width: 70% !important;
  }
  
  .res-inputs .ant-col-xl-3{ 
    display: block !important;
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
    width: 12.5% !important;
  }

  .res-inputs .ant-col-xl-5 {
    display: block !important;
    flex: 0 0 20.83333333% !important;
    max-width: 20.83333333% !important;
    width: 20.83333333% !important;
  }

  /* Help */
  .help-print .ant-col-xl-8 {
    display: block !important;
    flex: 0 0 33.33333333% !important;
    max-width: 33.33333333% !important;
    width: 33.33333333% !important;
  }

  .helptabs-print .ant-col-md-17 {
    display: block !important;
    flex: 0 0 70% !important;
    max-width: 70% !important;
}


/*Bookmarks print*/
.book-print, .sub-print {
  display: block !important;
  flex: 0 0 33.33333333% !important;
  max-width: 33.33333333% !important;
  width: 33.33333333% !important;
}

/*Profile and Data table*/
.pro-right-print {
  display: block !important;
  flex: 0 0 54.16666667% !important;
  max-width: 54.16666667% !important;
}

.pro-left-print {
  display: block !important;
  flex: 0 0 45.83333333% !important;
  max-width: 45.83333333% !important;
}

.meta-ind-print {
  display: block !important;
  flex: 0 0 12.5% !important;
  max-width: 12.5% !important;
}
/* Collection*/
.col-left-print, .col-right-print{
  display: block !important;
  flex: 0 0 50% !important;
  max-width: 50% !important;
}

/* Catalogue */
 .cat-head-left-print {
  width: 0 !important;
 }
 .cat-head-right-print {
  position: absolute;
  left: 300px;
 }

}