@import '~antd/dist/antd.less';
@import './Colors.less';
@import './util.module.less';

@desktop: ~'only screen and (min-width: 960px) and (max-width: 1199px)';
@tablet: ~'only screen and (min-width: 720px) and (max-width: 959px)';
@smartPhone: ~'only screen and (max-width : 720px)';

html,
body {
  scroll-behavior: smooth;
  padding:0;
}

:global {
  .ant-popover-inner {
    .ant-popover-inner-content {
      padding: 15px !important;
    }
  }

  .ant-button {
    border-radius: 4px;
  }

  .ant-drawer-body {
    padding: 0 !important;
  }

  .ant-tabs-tab-btn {
    font-size: 18px;
  }

  .ant-badge-count {
    background: @color-primary !important;
  }

  .ant-btn-link {
    color: @color-primary;
  }

  .ant-upload.ant-upload-select-picture-card {
    border-radius: 50% !important;
  }

  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  *::-webkit-scrollbar-thumb,
  *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
  }
  .ant-statistic-title {
    margin-bottom: 4px;
    color: rgba(0, 0, 0, .7) !important;
    font-size: 14px;
}
.ant-statistic-content-value {
  display: inline-block;
  direction: ltr;
  font-weight: 500;
}
.ant-typography.ant-typography-secondary {
  color: rgba(0, 0, 0, 0.7) !important;
}
}

.commonLink {
  color: @color-primary;
}

.textUnderlined {
  text-decoration: underline;
}

.textGreyed {
  color: #778899;
}

.textCenterd {
  text-align: center;
}

.textEnd {
  text-align: end;
}

.commonMargin {
  margin: 10px 50px;
}

.closeIcon {
  margin-top: 5px;
  font-size: 20px;
  color: @black-hex !important;
}

.breakTextDiv {
  word-break: break-all;
  white-space: pre-wrap;
}

.btnTransparent {
  background-color: @transparent-hex !important;
  color: @white-hex !important;
  border: none !important;
}

.commonJustifyCenter {
  display: flex !important;
  justify-content: center;
}

.commonAlignMiddle {
  display: flex !important;
  align-items: center;
}

.fontSizeBig {
  h1 {
    font-size: 2.8rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.55rem;
  }

  h4 {
    font-size: 1.3rem;
  }

  h5 {
    font-size: 1.05rem;
  }

  h6 {
    font-size: 0.8em;
  }

  :global {
    .ant-btn {
      font-size: 16px;
    }
  }
}

.fontSizeNormal {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1rem;
  }

  h5 {
    font-size: 0.75rem;
  }

  h6 {
    font-size: 0.5rem;
  }

  :global {
    .ant-btn {
      font-size: 14px;
    }
  }
}

.fontSizeSmall {
  h1 {
    font-size: 2.2rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 0.95rem;
  }

  h4 {
    font-size: 0.7rem;
  }

  h5 {
    font-size: 0.45rem;
  }

  h6 {
    font-size: 0.2rem;
  }

  :global {
    .ant-btn {
      font-size: 12px;
    }
  }
}

.zoomSizeNormal {
  zoom: 100%;
}

.zoomSizeVeryBig {
  zoom: 102%;
}

.zoomSizeBig {
  zoom: 101%;
}

.zoomSizeSmall {
  zoom: 99%;
}

.zoomSizeVerySmall {
  zoom: 98%;
}

.star {
  color: @color-icon !important;
}

.iconFontSize1 {
  font-size: 15px !important;
}

.iconFontSize2 {
  font-size: 20px !important;
}

.iconFontSize3 {
  font-size: 25px !important;
}

b,
strong {
  font-weight: 700 !important;
}

.timeline-dotted .ant-timeline-item-tail {
  border-left: 2px dotted @color-lightish-skyblue !important;
}

.timeline-with-scrollspy li:last-child .ant-timeline-item-tail {
  display: none;
}

.timeline-with-scrollspy li:not(.is-current) .star {
  color: @color-lightish-skyblue !important;
}

.virtual-table-cell {
  padding: 8px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-modal-wrap {
  overflow: hidden;
}

.split-tables .ant-col:last-child .ant-table-wrapper {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;

  -webkit-border-top-right-radius: 12px !important;
  -webkit-border-bottom-right-radius: 12px !important;

  -moz-border-top-right-radius: 12px !important;
  -moz-border-bottom-right-radius: 12px !important;

  -ms-border-top-right-radius: 12px !important;
  -ms-border-bottom-right-radius: 12px !important;

  border-right: 1px solid @color-greyish-dark !important;
}

.split-tables .ant-col:first-child .ant-table-wrapper {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;

  -webkit-border-top-left-radius: 12px !important;
  -webkit-border-bottom-left-radius: 12px !important;

  -moz-border-top-left-radius: 12px !important;
  -moz-border-bottom-left-radius: 12px !important;

  -ms-border-top-left-radius: 12px !important;
  -ms-border-bottom-left-radius: 12px !important;
}

.split-tables .ant-col .ant-table-wrapper {
  border-right: none;
  border-radius: 0px;
}

.min-height-30vh div.ant-table-body {
  min-height: 30vh !important;
  scrollbar-width: thin;
  overflow: auto auto !important;
}

.split-tables .table-title {
  max-height: 40px;
  min-height: 20px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.split-tables .ant-table-wrapper {
  height: 100% !important;
}

.split-tables table colgroup col:last-child {
  width: 0 !important;
}

.border-dotted-select .ant-select-selector {
  border-style: dashed !important;
}

.ant-modal-content {
  border-radius: 12px;
  overflow: hidden;
}

.ant-modal-header {
  border-radius: 12px 12px 0 0;
}

.bg-color-gray-viz-notify,
.bg-color-gray-viz-notify .ant-card-body {
  background: @color-white-lighter !important;
}

.color-light-dark {
  color: @color-light-dark !important;
}

.bar-separater {
  position: relative;
}

.bar-separater>*:not(:last-child)::after {
  content: '';
  border-right: 1px solid @color-border-light;
  background-color: @color-border-light;
  margin-left: 16px;
  margin-right: 4px;
}

.split-tables .ant-table-tbody>tr>td {
  border: 0 !important;
}

.color-medium-grey {
  color: @color-medium-grey !important;
}

.color-blue {
  color: @color-blue !important;
}

.custom-notification .ant-modal-confirm-btns {
  display: none !important;
}

.combined-filter-modal .ant-modal-content,
.combined-filter-modal .ant-modal-footer,
.combined-filter-modal .ant-modal-header {
  background: @color-lightish-grey !important;
}

.combined-filter-modal .ant-modal-body {
  background: @white-hex !important;
  margin: 0px 20px !important;
  padding: 0px 12px 14px 12px !important;
  border-radius: 8px;
}

.combined-filter-modal .ant-modal-footer,
.combined-filter-modal .ant-modal-header {
  border: none !important;
}

.combined-filter-modal .ant-tabs-nav::before {
  border: none !important;
}

.height-transition {
  transition: max-height 0.15s ease-out;
  transition: min-height 0.15s ease-out;
}

.ant-modal-body .ant-pagination.mini .ant-pagination-item {
  min-width: auto !important;
}

.ant-modal-body .ant-pagination-options {
  width: 160px !important;
}

body .ant-tooltip {
  z-index: 9999 !important;
}

.border-0>.ant-btn {
  border: 0px !important;
}

.userback-controls-attachment-name>span {
  line-height: 18px !important;
}

.userback-controls-attachment-name {
  line-height: 18px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}
@primary-color: #2E5CF6;