#root {
  height: 100vh;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f4f8 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.product-content {
  text-align: left;
}

.dxm-label {
  fill: #fff !important;
}
.ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, .7) !important;
  font-size: 14px;
}
.ant-statistic-content-value {
display: inline-block;
direction: ltr;
font-weight: 500;
}
.ant-typography.ant-typography-secondary {
color: rgba(0, 0, 0, 0.7) !important;
}